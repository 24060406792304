export const categories: TableModule = {
    name: "categories",
    route: "spa.basedata.categories",
    disablePreloadPages: true,
    defaultOrder: {
        column: "categories.name|position",
        direction: "asc"
    },
    columns: [
        {
            name: "name",
            sortable: true,
            pageLink: true,
            sortBy: "name",
            mobile: true,
        }
    ],
    tools: [
        {
            name: "categories.create",
            isCreateAction: true,
            ability: [
                'create',
                'categories'
            ]
        }
    ]
}
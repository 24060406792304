export const parishes: TableModule = {
    name: "parishes",
    route: "spa.jugendzaehlt.parishes",
    idColumn: "kro",
    defaultOrder: {
        column: "name",
        direction: "asc"
    },
    columns: [
        {
            name: "customName",
            sortable: true,
            sortBy: "parishes.name",
            pageLink: true,
            mobile: true,
            width: "w-1/3 md:w-1/6"
        },
        {
            name: "region_name",
            sortable: true,
            mobile: true
        },
        {
            name: "customAddress",
            width: "w-1/6",
            breakpoint: 'lg',
        },
        {
            name: "customCommunications",
            width: "w-1/6",
            breakpoint: 'xl',
        },
        {
            name: "valid_report",
            mobile: true
        },
        {
            name: "members_all",
            sortable: true,
            breakpoint: 'md',
        },
        {
            name: "members_youth",
            sortable: true,
            breakpoint: 'md',
        },
        {
            name: "average_age",
            sortable: true,
            breakpoint: 'md',
        },
    ],
    filters: [
        {
            name: "kro",
            column: "regions.reference_number",
            type: "numeric"
        },
        {
            name: "name",
            column: "regions.name",
            type: "string"
        },
    ],
}
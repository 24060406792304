export const years: TableModule = {
    name: "years",
    route: "spa.basedata.years",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "id",
            sortable: true,
            pageLink: true,
            mobile: true
        },
        {
            name: "is_active",
            sortable: true,
            mobile: true
        },
        {
            name: "is_visible",
            sortable: true,
            mobile: true
        }
    ],
    filters: [],
    quickFilters: [],
    tools: [
        {
            name: "years.create",
            isCreateAction: true,
            ability: [
                "create",
                "years"
            ]
        }
    ]
}
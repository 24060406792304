export const regions: TableModule = {
    name: "regions",
    route: "spa.jugendzaehlt.regions",
    idColumn: "reference_number",
    defaultOrder: {
        column: "name",
        direction: "asc"
    },
    columns: [
        {
            name: "customName", // name/reference_number
            sortable: true,
            pageLink: true,
            mobile: true,
            width: "w-1/3 md:w-1/6"
        },
        {
            name: "parent_region_id",
            sortable: true,
            mobile: true
        },
        {
            name: "token",
            mobile: true
        },
        {
            name: "members_all",
            sortable: true,
            breakpoint: 'md',
        },
        {
            name: "members_youth",
            sortable: true,
            breakpoint: 'md',
        },
        {
            name: "average_age",
            sortable: true,
            breakpoint: 'md',
        },
        {
            name: "location",
            breakpoint: 'xl',
        },
    ],
    filters: [
        {
            name: "reference_number",
            column: "regions.reference_number",
            type: "numeric"
        },
        {
            name: "name",
            column: "regions.name",
            type: "string"
        },
    ],
    tools: [
        {
            name: "regions.tokens",
            icon: "heroicons:hashtag-16-solid"
        },
        {
            name: "regions.export",
            icon: "heroicons:hashtag-16-solid"
        },
    ]
}
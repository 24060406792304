export const loginProviders: TableModule = {
    name: "loginProviders",
    route: "spa.settings.loginProviders",
    columns: [
        {
            name: "id",
            sortable: true
        },
        {
            name: "provider",
            pageLink: true,
            sortable: true,
            mobile: true
        },
        {
            name: "client_id"
        },
        {
            name: "created_at",
            sortable: true,
            mobile: true
        }
    ],
    filters: [
        {
            name: "provider",
            column: "socialite_providers.provider",
            type: "checkbox",
            options: [
                {
                    value: "google",
                    label: "loginProviders.providers.google"
                },
                {
                    value: "azure",
                    label: "loginProviders.providers.azure"
                }
            ]
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: [
                "create",
                "loginProviders"
            ]
        }
    ]
}
export const payments: TableModule = {
    name: "payments",
    route: "spa.payments.payments",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "id",
            sortBy: "payments.id",
            sortable: true,
            pageLink: true,
            mobile: true,
            width: "w-[100px]"
        },
        {
            name: "process",
            sortable: true,
            sortBy: "process_id",
            breakpoint: 'lg',
        },
        {
            name: "organisation",
            sortable: true,
            sortBy: "organisation_id",
            width: "w-1/4 sm:w-auto",
            breakpoint: 'lg',
        },
        {
            name: "intended_use",
            sortable: true,
        },
        {
            name: "amount",
            sortable: true,
        },
    ],
    filters: [],
    quickFilters: [],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
    ]
}
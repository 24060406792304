import {useStatic} from "@/store/static";

export const processes: TableModule = {
    name: "processes",
    route: "spa.processes.processes",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "serial_number",
            sortable: true,
            pageLink: true,
            sortBy: "customOrderProcessNumber",
            mobile: true,
            width: "w-1/2 sm:w-auto"
        },
        {
            name: "funding_program",
            sortable: true,
            sortBy: "funding_program_id",
            breakpoint: 'lg',
        },
        {
            name: "organisation",
            sortable: true,
            sortBy: "organisation_id",
            width: "w-1/4 sm:w-auto",
            breakpoint: 'lg',
        },
        {
            name: "period",
            sortable: true,
            sortBy: "start_date"
        },
        {
            name: "event",
            sortable: true,
            sortBy: "events.name",
            breakpoint: 'lg',
        },
        {
            name: "formProcesses",
            mobile: true,
            width: "w-[100px]"
        },
        {
            name: "subsidy_granted",
            mobile: true,
            sortable: true,
            sortBy: "subsidy_open_payment",
            width: "w-1/4 sm:w-auto"
        },
        {
            name: "status",
            sortable: true,
            sortBy: "status_id",
            width: "w-[100px]"
        },
    ],
    filters: [
        {
            name: "id",
            column: "processes.id",
            type: "numeric"
        },
        {
            name: "freetext",
            column: "freetext",
            type: "string",
            reduced: true
        },
        {
            name: "serial_number",
            column: "processes.serial_number",
            type: "string",
            reduced: true,
            operator: "="
        },
        {
            name: "year",
            column: "processes.year_id",
            type: "select",
            options: computed(() => {
                const staticStore = useStatic()
                return reactive(staticStore?.years?.map((year: any) => {
                    return {
                        value: year.id,
                        label: year.id
                    }
                }) || [])
            }),
            reduced: true
        },
        {
            name: "fundingProgram",
            column: "processes.funding_program_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.basedata.fundingPrograms.index')
            ).then(response => response.data?.map(
                status => ({
                    value: status.id,
                    label: status.name_management_area
                }))
            ),
            reduced: true,
            multiple: true
        },
        {
            name: "organisation",
            column: "processes.organisation_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.settings.organisations.index')
            ).then(response => response.data?.map(
                status => ({
                    value: status.id,
                    label: status.name
                }))
            ),
            reduced: true,
            multiple: true
        },
        {
            name: "status",
            column: "processes.status_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.processes.processes.statuses.index')
            ).then(response => response.data?.map(
                status => ({
                    value: status.id,
                    label: status.name_management_area
                }))
            ),
            reduced: true,
            multiple: true
        },
        {
            name: "customFilterHasOpenPayment",
            column: "customFilterHasOpenPayment",
            type: "boolean",
            reduced: true
        },
    ],
    quickFilters: [
        {
            name: "qf_ownOnly",
            column: "customFilterOwnOnly",
            value: "1",
            operator: "="
        }
    ],
    tools: [
        {
            name: "processes.paymentRuns",
            icon: "heroicons:arrow-down-tray",
            ability: ["create", "payment"]
        }
    ]
}
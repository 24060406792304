export const organisations: TableModule = {
    name: "organisations",
    route: "spa.settings.organisations",
    columns: [
        {
            name: "name",
            pageLink: true,
            sortable: true,
            sortBy: "name",
            mobile: true
        },
        {
            name: "type",
            sortable: true,
        },
        {
            name: "active",
            sortable: true
        },
        {
            name: "categories",
        }
    ],
    filters: [
        {
            name: "active",
            column: "organisations.active",
            type: "boolean"
        },
        {
            name: 'parent_organisation_id',
            column: 'organisations.parent_organisation_id',
            type: 'searchSelect',
            apiRoute: 'spa.settings.organisations.index',
        },
        {
            name: 'category',
            column: 'categories.id',
            relation: 'categories',
            type: 'searchSelect',
            apiRoute: 'spa.basedata.categories.index',
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "organisations"]
        },
        {
            name: "organisations.toggle",
            color: "rose"
        },
        {
            name: "organisations.attachCategory",
        }
    ]
}

export const userOrganisations: TableModule = {
    name: "userOrganisations",
    route: "spa.settings.organisationUsers",
    columns: [
        {
            name: "organisation_name",
            pageLink: true,
            sortable: true,
            sortBy: "organisation_name",
            mobile: true
        },
        {
            name: "role_name_de",
            sortable: true,
            sortBy: "role_name_de",
            mobile: true
        },
        {
            name: "connections",
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "organisationUsers"]
        }
    ]
}

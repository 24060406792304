export const fundingPrograms: TableModule = {
    name: "fundingPrograms",
    route: "spa.basedata.fundingPrograms",
    defaultOrder: {
        column: "customOrderSortByParent",
        direction: "asc"
    },
    columns: [
        {
            name: "name",
            sortable: true,
            pageLink: true,
            mobile: true
        },
        {
            name: "short_name",
            sortable: true
        },
        {
            name: "is_active",
            sortable: true
        }
    ],
    filters: [
        {
            name: "name",
            column: "search",
            type: "string"
        }
    ],
    tools: [
        {
            name: "fundingPrograms.create",
            isCreateAction: true,
            ability: [
                "create",
                "fundingPrograms"
            ]
        }
    ]
}
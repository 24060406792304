export const paymentRuns: TableModule = {
    name: "paymentRuns",
    route: "spa.payments.paymentRuns",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "id",
            sortBy: "payment_runs.id",
            sortable: true,
            pageLink: true,
            mobile: true,
            width: "w-[100px]"
        },
        {
            name: "funding_program",
            sortable: true,
            sortBy: "funding_program_id",
            breakpoint: 'lg',
        },
        {
            name: "organisation",
            sortable: true,
            sortBy: "organisation_id",
            width: "w-1/4 sm:w-auto",
            breakpoint: 'lg',
            mobile: true,
        },
        {
            name: "intended_use",
            sortable: true,
        },
        {
            name: "sum",
            sortable: true,
            mobile: true,
        },
        {
            name: "created_at",
            sortable: true,
            mobile: true,
        },
        {
            name: "documents",
            width: "w-[150px]"
        },
        {
            name: "payments",
            width: "w-[100px]"
        },
    ],
    filters: [],
    quickFilters: [],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
    ]
}
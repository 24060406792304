import { defineStore } from 'pinia'
import { useTableStore } from '@/composables/useTableStore'
import { tableModules } from '@/utils/tableModules'

export const tableStores = createTableStores()

function createTableStores() {
    let storeModules = {}
    for (let registeredModule of Object.keys(tableModules)) {
        storeModules[registeredModule] = defineStore(registeredModule, () => {
            const tableStore = useTableStore()
            tableStore.initialize(registeredModule)
            return {
                ...tableStore,
            }
        })
    }
    return storeModules
}
import {categories} from "./tableModules/categories"
import {contacts} from './tableModules/contacts'
import {forms} from './tableModules/forms'
import {fundingPrograms} from './tableModules/fundingPrograms'
import {loginProviders} from './tableModules/loginProviders'
import {mailTemplates} from './tableModules/mailTemplates'
import {organisations} from "./tableModules/organisations";
import {organisationUsers} from "./tableModules/organisationUsers"
import {parishes} from './tableModules/parishes'
import {paymentRuns} from "./tableModules/paymentRuns"
import {payments} from "./tableModules/payments"
import {processes} from './tableModules/processes'
import {regions} from './tableModules/regions'
import {roles} from "./tableModules/roles"
import {statuses} from "./tableModules/statuses"
import {userOrganisations} from "./tableModules/userOrganisations"
import {users} from './tableModules/users'
import {years} from "./tableModules/years"

export const tableModules: TableModules = {
    categories,
    contacts,
    forms,
    fundingPrograms,
    loginProviders,
    mailTemplates,
    organisations,
    organisationUsers,
    parishes,
    paymentRuns,
    payments,
    processes,
    processGroups: {
        ...processes,
        name: 'processGroups',
    },
    regions,
    regionParishes: {
        ...parishes,
        columns: parishes.columns.filter(column => column.name !== 'region_name').map(
            column => column.name === 'customName' ? {...column, width: 'w-1/2 md:w-1/3'} : column
        )
    },
    roles,
    statuses,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    userOrganisations,
    users,
    years,
}

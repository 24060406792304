export const forms: TableModule = {
    name: "forms",
    route: "spa.basedata.forms",
    defaultOrder: {
        column: "funding_programs.name|sort",
        direction: "asc"
    },
    columns: [
        {
            name: "name",
            sortable: true,
            pageLink: true,
            sortBy: "name",
            mobile: true,
            width: "w-2/6 sm:w-auto"
        },
        {
            name: "identifier",
            sortable: true,
            pageLink: true,
            sortBy: "identifier",
            width: "w-1/6"
        },
        {
            name: "fundingProgram",
            sortable: true,
            sortBy: "funding_programs.name",
            width: "w-1/6 sm:w-auto"
        },
        {
            name: "is_active",
            width: "w-1/6"
        },
        {
            name: "sort",
            sortable: true,
            pageLink: true,
            sortBy: "sort",
            mobile: true,
            width: "w-1/6"
        }
    ],
    filters: [
        {
            name: "id",
            column: "forms.id",
            type: "numeric"
        },
        {
            name: "name",
            column: "forms.name",
            type: "string"
        },
        {
            name: "fundingProgram",
            column: "forms.funding_programs.name",
            type: "string"
        }
    ],
    quickFilters: [
        {
            name: "qf_activeOnly",
            column: "customFilterActive",
            value: "1",
            operator: "="
        }
    ],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "forms.delete",
            color: "rose",
            icon: "heroicons:trash"
        },
        {
            name: "forms.create",
            isCreateAction: true,
            ability: [
                "create",
                "forms"
            ]
        }
    ]
}
export const roles: TableModule = {
    name: "roles",
    idColumn: "name",
    route: "spa.basedata.roles",
    defaultOrder: {
        column: "name",
        direction: "asc"
    },
    columns: [
        {
            name: "name",
            sortable: true,
            pageLink: true,
            mobile: true
        },
        {
            name: "name_de",
            sortable: true,
            mobile: true
        }
    ],
    filters: [
        {
            name: "name",
            column: "name",
            type: "string"
        },
        {
            name: "name_de",
            column: "name_de",
            type: "string"
        }
    ],
    tools: [
        {
            name: "roles.create",
            isCreateAction: true,
            ability: [
                "create",
                "roles"
            ]
        }
    ]
}
export const organisationUsers: TableModule = {
    name: "organisationUsers",
    route: "spa.settings.organisationUsers",
    defaultOrder: {
        column: "lastname|firstname",
        direction: "asc"
    },
    columns: [
        {
            name: "customName",
            sortable: true,
            pageLink: true,
            sortBy: "lastname|firstname",
            mobile: true
        },
        {
            name: "role_name_de",
            sortable: true,
            sortBy: "role_name_de",
            mobile: true
        }
    ],
    tools: [
        {
            isCreateAction: true,
            ability: ["create", "organisationUsers"]
        }
    ]
}

export const mailTemplates: TableModule = {
    name: "mailTemplates",
    route: "spa.basedata.mailTemplates",
    idColumn: "name",
    defaultOrder: {
        column: "name",
        direction: "asc"
    },
    columns: [
        {
            name: "name",
            sortable: true,
            pageLink: true,
            mobile: true,
        },
        {
            name: "subject_de",
            sortable: true
        },
        {
            name: "content_de"
        },
    ],
    filters: [],
    quickFilters: [],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "mailTemplates.delete",
            color: "rose",
            icon: "heroicons:trash"
        },
        {
            name: "mailTemplates.create",
            isCreateAction: true,
            ability: [
                "create",
                "mailTemplates"
            ]
        }
    ]
}